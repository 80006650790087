import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useMemo } from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { STAFF_ROLE, USER_ROLE } from "../../../commons/enum";
import "./company-admin-modal.scss";

import { useMyInfo } from "../../../hooks/user.hooks";
import StaffSignupForm from "../../signup/staff-signup-form";
import CustomButton, {
  CustomOutlineButton,
} from "../../../components/custom-button";
import {
  useCompanyConnectiveDetails,
  useUpdateCompany,
} from "../../../hooks/company.hooks";
import { useUpdateStaffById } from "../../../hooks/staff.hooks";
import { splitNameFromFullName } from "../../../utils/user-data";

const validationSchema = Yup.object({
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),

  brokerName: Yup.string("Enter broker name").required("Name is required"),
  phoneNumber: Yup.string("Enter broker Phone Number").required(
    "Phone Number is required"
  ),
  connectiveAccountId: Yup.string("Enter broker Connective Id"),
  introductionVideoUrl: Yup.string("Enter Introduction Video URL"),
  msTeamHook: Yup.string("Enter Microsoft Team Hook id"),
  slackHook: Yup.string("Enter Slack Hook id"),
  activeCampaignContactListId: Yup.string("Enter Active Campaign List id"),
  calendlyUrl: Yup.string("Enter broker Calendly URL"),
});

const CompanyAdminDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AddBrokerDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

AddBrokerDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CompanyAdminModal = ({ isOpen, handleClose, companyDetails = {} }) => {
  const { data: userInfo } = useMyInfo();
  const queryClient = useQueryClient();
  const updateCompany = useUpdateCompany();
  const updateBrokerDetailById = useUpdateStaffById();
  const companyConnectiveDetails = useCompanyConnectiveDetails(
    companyDetails?.domain
  );
  const { enqueueSnackbar } = useSnackbar();
  const isAdminUser = userInfo?.userRole === USER_ROLE.admin;

  const {
    adminDetails = {},
    name,
    domain,
    theme,
    businessPhoneNumber,
    customerSupportEmail,
    companyNumber,
    financialServicesLicenseNumber,
    creditLicenseNumber,
  } = companyDetails;

  const getStaffRole = adminDetails?.staffRoles
    ?.filter((role) => role.role !== STAFF_ROLE.COMPANY_ADMIN)
    ?.map((role) => role.role);

  const initialCompanyId =
    adminDetails?.companyId || isAdminUser ? "" : userInfo.companyId;
  const fullName = useMemo(() => {
    if (adminDetails?.user?.firstName) {
      return `${adminDetails?.user?.firstName} ${adminDetails?.user?.lastName}`;
    }
    return null;
  }, [adminDetails?.user?.firstName, adminDetails?.user?.lastName]);

  const formik = useFormik({
    initialValues: {
      email: adminDetails?.user?.email || "",
      brokerName: fullName || "",
      phoneNumber: adminDetails?.user?.phoneNumber || "",
      businessPhoneNumber: businessPhoneNumber || "",
      customerSupportEmail: customerSupportEmail || "",
      companyNumber: companyNumber || "",
      financialServicesLicenseNumber: financialServicesLicenseNumber || "",
      creditLicenseNumber: creditLicenseNumber || "",
      connectiveAccountId: companyConnectiveDetails?.accountId || "",
      connectivePartnerId: companyConnectiveDetails?.partnerId || "",
      connectiveApiKey: companyConnectiveDetails?.apiKey || "",
      connectiveApiToken: companyConnectiveDetails?.apiToken || "",
      introductionVideoUrl: adminDetails?.introductionVideoUrl || "",
      companyId: initialCompanyId,
      activeCampaignContactListId:
        adminDetails?.activeCampaignContactListId || "",
      calendlyUrl: adminDetails?.calendlyUrl || "",
      companyName: name || "",
      companyDomain: domain || "",
      primaryColor: theme?.primaryColor || "",
      secondaryColor: theme?.secondaryColor || "",
      companyLogoURL: theme?.logoUrl || "",
      companyIconURL: theme?.logoUrl || "",
      companyLogoDarkURL: theme?.logoDarkUrl || "",
      companyIconDarkURL: theme?.iconDarkUrl || "",
      imageUrl: adminDetails?.imageUrl || "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const [brokerFirstName, brokerLastName] = splitNameFromFullName(
        values.brokerName
      );
      const brokerDetailParams = {
        userDetails: {
          email: values.email,
          firstName: brokerFirstName,
          lastName: brokerLastName,
          phoneNumber: values.phoneNumber,
        },
        staffDetails: {
          brokerDetails: {
            brokerId: adminDetails.id,
            connectiveAccountId: values.connectiveAccountId,
          },
          companyId: values.companyId,
          introductionVideoUrl: values.introductionVideoUrl,
          calendlyUrl: values.calendlyUrl,
          imageUrl: values.imageUrl,
        },
      };
      const companyParams = {
        businessPhoneNumber: values.businessPhoneNumber,
        customerSupportEmail: values.customerSupportEmail,
        financialServicesLicenseNumber: values.financialServicesLicenseNumber,
        creditLicenseNumber: values.creditLicenseNumber,
        companyNumber: values.companyNumber,
        name: values.companyName,
        domain: values.companyDomain,
        themeDetails: {
          primaryColor: values.primaryColor,
          secondaryColor: values.secondaryColor,
          logoUrl: values.companyLogoURL,
          iconUrl: values.companyIconURL,
          logoDarkUrl: values.companyLogoDarkURL,
          iconDarkUrl: values.companyIconDarkURL,
        },
        connectiveAccountId: values.connectiveAccountId,
        connectivePartnerId: values.connectivePartnerId,
        connectiveApiKey: values.connectiveApiKey,
        connectiveApiToken: values.connectiveApiToken,
      };

      await updateBrokerDetailById.mutateAsync({
        id: adminDetails.id,
        params: brokerDetailParams,
      });
      await updateCompany.mutateAsync(
        {
          id: companyDetails.id,
          params: companyParams,
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Update Company Successfully!", {
              variant: "success",
            });
            queryClient.invalidateQueries("brokerList");
            queryClient.invalidateQueries("companyList");
            queryClient.invalidateQueries("companyConnectiveDetails");
            queryClient.invalidateQueries("companyTheme");
            queryClient.invalidateQueries("companyInfo");
            handleClose(companyDetails);
            formik.resetForm();
          },
          onError: (error) => {
            enqueueSnackbar(error?.data.message || "Internal Server Error", {
              variant: "error",
            });
          },
        }
      );
    },
  });

  const handleCloseModal = () => {
    handleClose(companyDetails);
    formik.resetForm();
  };

  return (
    <CompanyAdminDialog
      className="company-admin__dialog"
      open={isOpen}
      onClose={handleCloseModal}
    >
      <StaffSignupForm formik={formik} staffRole={getStaffRole?.[0]} />
      <DialogActions>
        <CustomOutlineButton label="Cancel" onClick={handleCloseModal} />
        <CustomButton
          label="Update"
          type="submit"
          onClick={formik.handleSubmit}
          isLoading={updateCompany.isLoading}
          disabled={updateCompany.isLoading}
        />
      </DialogActions>
    </CompanyAdminDialog>
  );
};

CompanyAdminModal.propTypes = {};

export default CompanyAdminModal;
